import React, { Suspense } from 'react';

import ReactDOM from 'react-dom/client';
import './stili/index.css';
import './stili/App01.css';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  createMemoryRouter,
  RouterProvider,
  redirect,
  Outlet,

} from "react-router-dom";
import './i18n';

//import App from './pagine/App';
const App  = React.lazy(() => import( './pagine/App'));
const Persone  = React.lazy(() => import( './pagine/Persone'));
const GruppiPage  = React.lazy(() => import( './pagine/GruppiPage'));
const AttivitaPage  = React.lazy(() => import( './pagine/AttivitaPage'));
const DettPersone  = React.lazy(() => import( './pagine/DettPersone'));
const Config  = React.lazy(() => import( './pagine/Config'));
const Video  = React.lazy(() => import( './pagine/Video'));
const ProfiliUtenti  = React.lazy(() => import( './pagine/ProfiliUtenti'));
const TestConfPage  = React.lazy(() => import( './pagine/TestConfPage'));
const ReportisticaTest  = React.lazy(() => import( './pagine/ReportisticaTest'));
const DettSchedaOss  = React.lazy(() => import( './pagine/DettSchedaOss'));
const SchedeScouting  = React.lazy(() => import( './pagine/schedeScouting'));
const SchedaOssPage  = React.lazy(() => import( './pagine/schedaOssPage'));
const DettAttivita  = React.lazy(() => import( './pagine/DettAttivita'));
const GiocAttPage  = React.lazy(() => import( './pagine/GiocAttPage'));
const TeamPage  = React.lazy(() => import( './pagine/TeamPage'));
const PresenzePage  = React.lazy(() => import( './pagine/PresenzePage'));
const SchedaGiocPage  = React.lazy(() => import( './pagine/schedaGiocPage'));
const DettSchedaGioc  = React.lazy(() => import( './pagine/DettSchedaGioc'));
const EsecTestPage  = React.lazy(() => import( './pagine/EsecTestPage'));
const ReportisticaTestGioc  = React.lazy(() => import( './pagine/ReportisticaTestGioc'));
const LivePage  = React.lazy(() => import( './pagine/LivePage'));
const Reportistica  = React.lazy(() => import( './pagine/Reportistica'));
const ReportisticaGioc  = React.lazy(() => import( './pagine/ReportisticaGioc'));
const GraficoPage  = React.lazy(() => import( './pagine/GraficoPage'));
const LiveSetting  = React.lazy(() => import( './pagine/LiveSetting'));
const TestSensori  = React.lazy(() => import( './pagine/TestSensori'));
const AttivitaGiocatorePage  = React.lazy(() => import( './pagine/AttivitaGiocatore'));
const MyPage  = React.lazy(() => import( './pagine/MyPage'));
const MatchAnalysisPage  = React.lazy(() => import( './pagine/MatchAnalysis'));


//const Ordini  = React.lazy(() => import( './pagine/Ordini'));

const HomeUtente  = React.lazy(() => import( './pagine/HomeUtente'));

// import i18n (needs to be bundled ;))

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
/*root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);*/

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,

    children: [
      
      {
        path: "/homeUtente",
        element: <Suspense fallback={<div>Loading...</div>}><HomeUtente /> </Suspense>,
      },
      {
        path: "/persone",
        element: <Suspense fallback={<div>Loading...</div>}><Persone /> </Suspense>,
      },
      {
        path: "/gruppi",
        element: <Suspense fallback={<div>Loading...</div>}><GruppiPage /> </Suspense>,
      },
      {
        path: "/attivita",
        element: <Suspense fallback={<div>Loading...</div>}><AttivitaPage /> </Suspense>,
      },
      {
        path: "/DettAttivita",
        element: <Suspense fallback={<div>Loading...</div>}><DettAttivita /> </Suspense>,
      },
      {
        path: "/DettPersone",
        element: <Suspense fallback={<div>Loading...</div>}><DettPersone /> </Suspense>,
      },
      {
        path: "/Config",
        element: <Suspense fallback={<div>Loading...</div>}><Config/> </Suspense>,
      },
      {
        path: "/Video",
        element: <Suspense fallback={<div>Loading...</div>}><Video/> </Suspense>,
      },
      {
        path: "/ProfiliUtenti",
        element: <Suspense fallback={<div>Loading...</div>}><ProfiliUtenti/> </Suspense>,
      },
      {
        path: "/TestConfPage",
        element: <Suspense fallback={<div>Loading...</div>}><TestConfPage/> </Suspense>,
      },

      {
        path: "/DettSchedaOss",
        element: <Suspense fallback={<div>Loading...</div>}><DettSchedaOss/> </Suspense>,
      },
      {
        path: "/DettSchedaGioc",
        element: <Suspense fallback={<div>Loading...</div>}><DettSchedaGioc/> </Suspense>,
      },
      {
        path: "/schedeScouting",
        element: <Suspense fallback={<div>Loading...</div>}><SchedeScouting/> </Suspense>,
      },   
      {
        path: "/schedaOssPage",
        element: <Suspense fallback={<div>Loading...</div>}><SchedaOssPage/> </Suspense>,
      },
      {
        path: "/schedaGiocPage",
        element: <Suspense fallback={<div>Loading...</div>}><SchedaGiocPage/> </Suspense>,
      },
      {
        path: "/giocAttPage",
        element: <Suspense fallback={<div>Loading...</div>}><GiocAttPage/> </Suspense>,
      },
      {
        path: "/TeamPage",
        element: <Suspense fallback={<div>Loading...</div>}><TeamPage/> </Suspense>,
      },
      {
        path: "/PresenzePage",
        element: <Suspense fallback={<div>Loading...</div>}><PresenzePage/> </Suspense>,
      },
      {
        path: "/EsecTestPage",
        element: <Suspense fallback={<div>Loading...</div>}><EsecTestPage/> </Suspense>,
      },
      {
        path: "/ReportisticaTest",
        element: <Suspense fallback={<div>Loading...</div>}><ReportisticaTest/> </Suspense>,
      },
      {
        path: "/ReportisticaTestGioc",
        element: <Suspense fallback={<div>Loading...</div>}><ReportisticaTestGioc/> </Suspense>,
      },
      {
        path: "/LivePage",
        element: <Suspense fallback={<div>Loading...</div>}><LivePage/> </Suspense>,
      },
      {
        path: "/Reportistica",
        element: <Suspense fallback={<div>Loading...</div>}><Reportistica/> </Suspense>,
      },
      {
        path: "/ReportisticaGioc",
        element: <Suspense fallback={<div>Loading...</div>}><ReportisticaGioc/> </Suspense>,
      },
      {
        path: "/GraficoPage",
        element: <Suspense fallback={<div>Loading...</div>}><GraficoPage/> </Suspense>,
      }, 
      {
        path: "/LiveSetting",
        element: <Suspense fallback={<div>Loading...</div>}><LiveSetting/> </Suspense>,
      },
      {
        path: "/AttivitaGiocatore",
        element: <Suspense fallback={<div>Loading...</div>}><AttivitaGiocatorePage/> </Suspense>,
      },
      {
        path: "/MyPage",
        element: <Suspense fallback={<div>Loading...</div>}><MyPage/> </Suspense>,
      },
      {
        path: "/testsensor",
        element: <Suspense fallback={<div>Loading...</div>}><TestSensori/> </Suspense>,
      },
      {
        path: "/MatchAnalysis",
        element: <Suspense fallback={<div>Loading...</div>}><MatchAnalysisPage/> </Suspense>,
      },
      /*{
        path: "/Ordini",
        element: <Suspense fallback={<div>Loading...</div>}><Ordini/> </Suspense>,
      }*/
      ]
  }
]);
root.render(
  <React.StrictMode>
    
      <RouterProvider router={router} />
    
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


